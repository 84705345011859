module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BlockchainDevelopment","short_name":"blockchain","start_url":"/","background_color":"#ffffff","theme_color":"#f2f2f2","display":"minimal-ui","icon":"src/images/icon-black.jpg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"13c12197d983d865e2a637bc8a02a65b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-100845170-6","head":false,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
